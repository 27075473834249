@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white !important;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-flex {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkColor{
  color:#27496D;
  font-weight: bold !important;
  text-decoration: none;
}

.img-logo {
  width: 65px;
  height: 50px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .header-flex {
    padding: 0;
    justify-content: center;
  }
  .invisible-typography {
    display: none;
  }
}

.ant-layout {
  margin: 0% 7% 0% 7%;
  padding: 0;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .ant-layout {
    margin: 0% 4% 0% 4%;
    padding: 0;
  }
}

.row__decoration{
  box-shadow: 6px 6px 6px #00000029;
}


.titulo{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #27496D;
  text-align:center;
  margin-top:8rem;
}

.subtitulo{
  margin-top:5rem !important;
}

.img__principal{
  text-align: center;
}

.img__principal img{
  width:100%;
  height:100%;
  border-radius:5px;
}

.contenedor__nosotros{
  display:grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  padding:10px;
}

.img-objetivo{
  margin:0 auto;
  width:80%;
  height:80%;
}

.parrafo__nosotros{
  color:black;
  font-size:18px;
}

.contenedor__cards{
  display:grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  grid-row-gap: 5rem;
  row-gap: 5rem;
  margin-top:5rem;
}

.footer{
  width:100%;
  background-color:#27496D;
  color:white;
  font-size:12px;
  margin-top:8rem;
  text-align: center;
  height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width:576px){
  .contenedor__cards{
    grid-template-columns: repeat(2,1fr);
    grid-row-gap: 0;
    row-gap: 0;
  }

  .footer{
    font-size:16px;
  }
}

@media screen and (min-width:768px){
  .titulo{
    font-size:35px;
  }

  .contenedor__nosotros{
    grid-template-columns: repeat(2,1fr);
  }

  .parrafo__nosotros{
    font-size:25px;
  }
}

@media screen and (min-width:1024px){
  .img__principal img{
    width:70%;
    height:500px;
  }
}

.ant-layout {
  margin: 0% 7% 0% 7%;
  padding: 0;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .ant-layout {
    margin: 0% 4% 0% 4%;
    padding: 0;
  }
}

.titulo__linguistica{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #27496D;
  text-align:center;
  margin-top:5rem;
}

.parrafo__instrucciones{
    color:#27496D;
    font-size:15px;
}

.parrafo__ejercicio{
    color:black;
    font-size:15px;
}

.contenedor__ejercicios{
    display:grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}

.revisar{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:5rem;
    margin-bottom:1rem;
}
  
@media screen and (min-width:576px){
    .contenedor__ejercicios{
        grid-template-columns: 55% 25% 20%;
    }

    .titulo__linguistica{
        margin-top:8rem;
    }
}

@media screen and (min-width:768px){
      .parrafo__instrucciones,
      .parrafo__ejercicio{
          font-size:20px;
      }

      .titulo__linguistica{
          font-size:35px;
      }
  }


