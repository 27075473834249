@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.ant-layout {
  margin: 0% 7% 0% 7%;
  padding: 0;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .ant-layout {
    margin: 0% 4% 0% 4%;
    padding: 0;
  }
}

.row__decoration{
  box-shadow: 6px 6px 6px #00000029;
}


.titulo{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #27496D;
  text-align:center;
  margin-top:8rem;
}

.subtitulo{
  margin-top:5rem !important;
}

.img__principal{
  text-align: center;
}

.img__principal img{
  width:100%;
  height:100%;
  border-radius:5px;
}

.contenedor__nosotros{
  display:grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  padding:10px;
}

.img-objetivo{
  margin:0 auto;
  width:80%;
  height:80%;
}

.parrafo__nosotros{
  color:black;
  font-size:18px;
}

.contenedor__cards{
  display:grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  row-gap: 5rem;
  margin-top:5rem;
}

.footer{
  width:100%;
  background-color:#27496D;
  color:white;
  font-size:12px;
  margin-top:8rem;
  text-align: center;
  height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width:576px){
  .contenedor__cards{
    grid-template-columns: repeat(2,1fr);
    row-gap: 0;
  }

  .footer{
    font-size:16px;
  }
}

@media screen and (min-width:768px){
  .titulo{
    font-size:35px;
  }

  .contenedor__nosotros{
    grid-template-columns: repeat(2,1fr);
  }

  .parrafo__nosotros{
    font-size:25px;
  }
}

@media screen and (min-width:1024px){
  .img__principal img{
    width:70%;
    height:500px;
  }
}
