.header-flex {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkColor{
  color:#27496D;
  font-weight: bold !important;
  text-decoration: none;
}

.img-logo {
  width: 65px;
  height: 50px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .header-flex {
    padding: 0;
    justify-content: center;
  }
  .invisible-typography {
    display: none;
  }
}
