@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.ant-layout {
  margin: 0% 7% 0% 7%;
  padding: 0;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .ant-layout {
    margin: 0% 4% 0% 4%;
    padding: 0;
  }
}

.titulo__linguistica{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #27496D;
  text-align:center;
  margin-top:5rem;
}

.parrafo__instrucciones{
    color:#27496D;
    font-size:15px;
}

.parrafo__ejercicio{
    color:black;
    font-size:15px;
}

.contenedor__ejercicios{
    display:grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}

.revisar{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:5rem;
    margin-bottom:1rem;
}
  
@media screen and (min-width:576px){
    .contenedor__ejercicios{
        grid-template-columns: 55% 25% 20%;
    }

    .titulo__linguistica{
        margin-top:8rem;
    }
}

@media screen and (min-width:768px){
      .parrafo__instrucciones,
      .parrafo__ejercicio{
          font-size:20px;
      }

      .titulo__linguistica{
          font-size:35px;
      }
  }

